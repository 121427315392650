<template>
  <div id="scraper-page">
    <b-card no-body class="h-100">
      <!-- header component -->
      <b-card-header>
        <!-- title -->
        <b-card-title class="d-flex align-items-center">
          <i class="bi bi-card-list"></i>
          <span style="margin-left: 5px"> Scraper Online News </span>
        </b-card-title>
      </b-card-header>
      <!-- body component -->
      <b-card-body
        class="d-flex flex-wrap gap-5px justify-content-between align-items-center pb-0"
      >
        <div class="d-flex flex-wrap gap-5px align-items-center">
          <!-- status filter -->
          <div>
            <select
              v-model="filter_data.status"
              id="Status"
              class="form-control"
              @change="getData()"
            >
              <option value="">Semua Status</option>
              <option value="1">Aktif</option>
              <option value="0">Nonaktif</option>
            </select>
          </div>
          <!-- cakupan filter -->
          <div>
            <select
              v-model="filter_data.cakupan"
              id="Cakupan"
              class="form-control"
              @change="getData()"
            >
              <option value="">Semua Cakupan</option>
              <option value="nasional">Nasional</option>
              <option value="internasional">Internasional</option>
            </select>
          </div>
          <!-- tier filter -->
          <div>
            <select
              v-model="filter_data.tier"
              id="Tier"
              class="form-control"
              @change="getData()"
            >
              <option value="">Semua Tier</option>
              <option value="0">Untier</option>
              <option value="1">Tier 1</option>
              <option value="2">Tier 2</option>
              <option value="3">Tier 3</option>
              <option value="4">Tier 4</option>
            </select>
          </div>
        </div>
        <div
          class="d-flex flex-wrap gap-5px justify-content-end align-items-center"
        >
          <!-- description only button -->
          <!-- <b-button
            :variant="
              filter_data.is_exist_description_only
                ? 'success'
                : 'outline-success'
            "
            class="btn-icon"
            v-b-tooltip.hover.top="'Hanya Tampilkan Yang Memiliki Keterangan'"
            @click="
              (filter_data.is_exist_description_only = !filter_data.is_exist_description_only),
                getData()
            "
          >
            <i class="bi bi-check-circle" style="font-size: 16px;"></i>
          </b-button> -->
          <!-- add scraper button -->
          <b-button variant="outline-info" v-b-modal.modal-add-scraper>
            <feather-icon icon="PlusIcon" />
            <span style="margin-left: 2px">Tambah Scraper</span>
          </b-button>
          <!-- export scraper button -->
          <b-button variant="outline-success" @click="exportScraper">
            <feather-icon icon="FileTextIcon" />
            <span style="margin-left: 2px">Export</span>
          </b-button>
          <!-- keyword filter -->
          <b-input-group class="input-group-merge" style="width: 20rem">
            <b-input-group-prepend is-text>
              <feather-icon icon="SearchIcon" />
            </b-input-group-prepend>
            <b-form-input
              placeholder="Masukkan kata kunci..."
              v-model="filter_data.key"
            />
          </b-input-group>
        </div>
      </b-card-body>
      <div>
        <!-- loader component -->
        <loader v-if="is_loading" />
        <!-- table component -->
        <dataTable
          v-else
          :data="table_data"
          :activateDag="activateDag"
          :editItems="editScraper"
          :deleteItems="deleteScraper"
          :viewDetail="viewDetail"
          :updateLabel="updateLabel"
          :is_row_clicked="true"
          :is_pagination="false"
          :action_button="table_data.action_button"
          class="mt-1"
        />
        <paginationInfo
          :page="pagination.page"
          :items="pagination.items"
          :count="pagination.count"
          @update-value="paginationChanged"
        />
      </div>
    </b-card>
    <!-- add scraper modal component -->
    <addScraper />
    <!-- edit scraper modal  component -->
    <editScraper :data="scraper_data" />
  </div>
</template>
<script>
import $ from "jquery";
import axios from "axios";
import useJwt from "@/auth/jwt/useJwt";
import dataTable from "@/views/components/dataTable.vue";
import loader from "@/views/components/loader.vue";
import addScraper from "@/views/components/scraper/addScraper.vue";
import editScraper from "@/views/components/scraper/editScraper.vue";
import paginationInfo from "@/views/components/paginationInfo.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardSubTitle,
  BCardBody,
  BInputGroup,
  BFormGroup,
  BInputGroupPrepend,
  BFormInput,
  BButton,
  VBModal,
  VBTooltip,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import {
  confirmAction,
  getErrorMessage,
  showActionResult,
  showLoaderAlert,
} from "@/helper/helper";

export default {
  name: "Scraper",
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BCardSubTitle,
    BInputGroup,
    BFormGroup,
    BInputGroupPrepend,
    BFormInput,
    BButton,
    paginationInfo,
    dataTable,
    loader,
    addScraper,
    editScraper,
  },
  data() {
    return {
      is_loading: true,
      filter_data: {
        key: "",
        tier: "",
        cakupan: "",
        status: "",
        is_exist_description_only: false,
      },
      pagination: {
        page: 1,
        items: 10,
        count: 0,
      },
      table_data: {
        fields: [
          {
            key: "no",
            label: "no",
            thClass: "text-center",
            tdClass: "text-center",
            thStyle: { width: "1rem" },
          },
          {
            key: "_id",
            label: "nama scraper",
            thClass: "text-left",
            tdClass: "text-left",
          },
          {
            key: "file_name",
            label: "nama file",
            thClass: "text-left",
            tdClass: "text-left",
          },
          {
            key: "domain",
            label: "domain",
            thClass: "text-left",
            tdClass: "text-left",
          },
          {
            key: "scraper_status",
            label: "status",
            thClass: "text-center",
            tdClass: "text-center",
            thStyle: { paddingRight: "2rem" },
          },
          {
            key: "description",
            label: "Keterangan",
          },
          {
            key: "tier",
            label: "tier",
            thClass: "text-center",
            tdClass: "text-center",
            thStyle: { paddingRight: "2rem" },
          },
          {
            key: "cakupan",
            label: "cakupan",
            thClass: "text-center",
            tdClass: "text-center",
            thStyle: { paddingRight: "2rem" },
          },
          {
            key: "last_fixing",
            label: "terakhir fixing",
            thClass: "text-center",
            tdClass: "text-center",
          },
          {
            key: "problem_classification",
            label: "klasifikasi masalah",
            thClass: "text-left",
          },
          {
            key: "solution",
            label: "solusi",
            thClass: "text-left",
          },
          {
            key: "action",
            label: "aksi",
            thClass: "text-center",
            tdClass: "text-center",
          },
        ],
        items: [],
        action_button: {
          dag: true,
          edit: true,
          delete: true,
        },
      },
      scraper_data: null,
      cancel_token: null,
    };
  },
  created() {
    this.getData();
  },
  watch: {
    "filter_data.key"() {
      if (
        this.filter_data.key.trim().length >= 2 ||
        this.filter_data.key.trim().length == 0
      ) {
        this.getData();
      }
    },
  },
  methods: {
    paginationChanged(data) {
      this.pagination = data;
      this.getData();
    },
    getData() {
      this.is_loading = true;
      if (this.cancel_token) {
        this.cancel_token.cancel("ERR_CANCELED");
      }
      this.cancel_token = axios.CancelToken.source();

      let params = {
        page: this.pagination.page,
        items: this.pagination.items,
        ...(this.filter_data.key ? { name: this.filter_data.key } : {}),
        ...(this.filter_data.tier ? { tier: this.filter_data.tier } : {}),
        ...(this.filter_data.cakupan
          ? { cakupan: this.filter_data.cakupan }
          : {}),
        ...(this.filter_data.status ? { status: this.filter_data.status } : {}),
        ...(this.filter_data.is_exist_description_only
          ? {
              is_exist_description_only: this.filter_data
                .is_exist_description_only,
            }
          : {}),
      };
      let query = Object.keys(params)
        .map((key) => key + "=" + params[key])
        .join("&");
      let api = process.env.VUE_APP_API_URL + "scraper?" + query;
      useJwt.axiosIns
        .get(api, { cancelToken: this.cancel_token.token })
        .then((res) => {
          this.cancel_token.value = null;
          this.table_data.items = res.data.scraper_data || [];
          this.pagination.count = res.data.pagination_info.count || 0;
        })
        .catch((err) => {
          if (err.message != "ERR_CANCELED") {
            this.cancel_token.value = null;
          } else {
            this.cancel_token.value = "CANCELED";
          }
        })
        .finally(() => {
          if (!this.cancel_token.value) {
            this.is_loading = false;
          }
        });
    },
    async activateDag(id, status) {
      const title = status == 1 ? "Nonaktifkan DAG?" : "Aktifkan DAG?";
      const subtitle = `DAG dari Scraper "${id}" akan ${
        status == 1
          ? "Dinonaktifkan. Mohon Masukkan Keterangan!"
          : "Diaktifkan!"
      }`;
      const confirm_button = `Ya, ${status == 1 ? "Nonaktifkan" : "Aktifkan"}!`;
      const is_confirmed = await confirmAction(
        title,
        subtitle,
        confirm_button,
        "question",
        status == 1 ? true : false
      );
      if (is_confirmed) {
        showLoaderAlert();
        let params = {
          id: id,
          description: is_confirmed,
          is_paused: status == 1 ? true : false,
        };
        let query = Object.keys(params)
          .map((key) => key + "=" + params[key])
          .join("&");
        let api =
          process.env.VUE_APP_API_URL + "early-warning-system/update?" + query;

        useJwt.axiosIns
          .put(api)
          .then(() => {
            showActionResult(
              `DAG Berhasil ${status == 1 ? "Dinonaktifkan!" : "Diaktifkan!"}`
            );
            this.getData();
          })
          .catch((err) => {
            const err_message = getErrorMessage(err);
            showActionResult(err_message, "error");
          });
      }
    },
    editScraper(id) {
      let filter_val = this.table_data.items.filter((el) => el._id == id);
      let scraper = filter_val[0];
      this.scraper_data = {
        new_id: scraper._id,
        _id: scraper._id,
        status: scraper.status,
        enable: scraper.enable,
        tier: scraper.tier || 0,
        file_name: scraper.file_name || "",
        cakupan: scraper.cakupan,
        media_name: scraper.media_name || [],
        domain: scraper.domain,
        description: scraper.description || "",
      };
      this.$bvModal.show("modal-edit-scraper");
    },
    deleteScraper(id, nama) {
      this.$swal({
        title: "Apakah Anda Yakin?",
        html: `<strong>${id}</strong> Akan dihapus dari daftar scraper`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Ya, Hapus!",
        cancelButtonText: "Batal",
        customClass: {
          confirmButton: "btn btn-info",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          let api = process.env.VUE_APP_API_URL + "scraper/delete/" + id;
          useJwt.axiosIns
            .delete(api)
            .then(() => {
              this.getData();
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: "Berhasil",
                  icon: "CheckCircleIcon",
                  variant: "success",
                  text: `Scraper berhasil dihapus`,
                },
              });
            })
            .catch(() => {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: "Gagal !",
                  icon: "AlertCircleIcon",
                  variant: "danger",
                  text: `Scraper gagal dihapus`,
                },
              });
            });
        }
      });
    },
    updateQuery(key, value) {
      let queries = JSON.parse(JSON.stringify(this.$route.query));
      queries[key] = value;
      if ($.param(this.$route.query) != $.param(queries)) {
        this.$router.replace({ query: queries });
      }
    },
    exportScraper() {
      let params = {};
      if (this.search) {
        params.name = this.search;
      }
      if (this.tier) {
        params.tier = parseInt(this.tier);
      }
      if (this.cakupan) {
        params.cakupan = this.cakupan;
      }
      if (this.status) {
        params.status = parseInt(this.status);
      }
      let query = Object.keys(params)
        .map((key) => key + "=" + params[key])
        .join("&");
      let api = process.env.VUE_APP_API_URL + "scraper/export";
      if (query) {
        api += "?" + query;
      }
      window.open(api);

      // const link = document.createElement("a");
      // link.href = api;
      // link.target = "_blank";
      // link.setAttribute("download", "List Scraper");
      // document.body.appendChild(link);
      // link.click();
      // document.body.removeChild(link);
    },
    viewDetail(id) {
      this.$router.push({
        name: "detail-logs",
        query: { scraper: id },
      });
    },
    updateLabel(id, value) {
      let api = `${process.env.VUE_APP_API_URL}scraper/update-status/${id}`;
      useJwt.axiosIns
        .put(api, {
          status: value,
        })
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "Berhasil",
              icon: "CheckCircleIcon",
              variant: "success",
              text: `Label Status Web Scraper berhasil diubah`,
            },
          });
          this.getData();
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "Gagal !",
              icon: "AlertCircleIcon",
              variant: "danger",
              text: `Label Web Scraper gagal diubah`,
            },
          });
        });
    },
  },
  directives: {
    "b-modal": VBModal,
    "b-tooltip": VBTooltip,
    Ripple,
  },
};
</script>
