<template>
  <b-modal id="modal-add-scraper" size="md" no-close-on-backdrop>
    <!-- custom modal title -->
    <template #modal-title>
      <div class="d-flex align-items-center">
        <feather-icon size="20" icon="PlusIcon" class="mr-05" />
        <span class="fs-18">Tambah Scraper Baru</span>
      </div>
    </template>
    <!-- form container -->
    <loader-component v-if="is_loading" />
    <b-card no-body class="mb-0">
      <b-card-body>
        <validation-observer ref="scraperRules">
          <b-form>
            <!-- Nama -->
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Nama Scraper"
                rules="required"
              >
                <label for="nama"
                  >Nama Scraper <span class="text-danger">*</span>
                </label>
                <b-input-group class="input-group-merge">
                  <b-input-group-prepend is-text>
                    <feather-icon icon="UserCheckIcon" />
                  </b-input-group-prepend>

                  <b-form-input
                    v-model="scraper_data.name"
                    id="nama"
                    placeholder="Masukkan Nama Scraper"
                  />
                </b-input-group>
                <small class="text-danger" style="font-size: 10px">{{
                  errors[0]
                }}</small>
              </validation-provider>
            </b-form-group>
            <!-- Nama File -->
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Nama File"
                rules="required"
              >
                <label for="file_name"
                  >Nama File <span class="text-danger">*</span>
                </label>
                <b-input-group class="input-group-merge">
                  <b-input-group-prepend is-text>
                    <feather-icon icon="FileIcon" />
                  </b-input-group-prepend>

                  <b-form-input
                    v-model="scraper_data.file_name"
                    id="file_name"
                    placeholder="Masukkan Nama File"
                  />
                </b-input-group>
                <small class="text-danger" style="font-size: 10px">{{
                  errors[0]
                }}</small>
              </validation-provider>
            </b-form-group>
            <!-- Status -->
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Status"
                rules="required"
              >
                <label for="Status">
                  Status <span class="text-danger">*</span>
                </label>
                <select
                  v-model="scraper_data.status"
                  id="Status"
                  class="form-control"
                >
                  <option value="1">Aktif</option>
                  <option value="0">Tidak Aktif</option>
                </select>
                <small class="text-danger" style="font-size: 10px">{{
                  errors[0]
                }}</small>
              </validation-provider>
            </b-form-group>
            <!-- Tier -->
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Tier"
                rules="required"
              >
                <label for="Tier">
                  Tier <span class="text-danger">*</span>
                </label>
                <select
                  v-model="scraper_data.tier"
                  id="Tier"
                  class="form-control"
                >
                  <option value="null" disabled selected>
                    Pilih Tier Scraper
                  </option>
                  <option value="0">Untier</option>
                  <option value="1">Tier 1</option>
                  <option value="2">Tier 2</option>
                  <option value="3">Tier 3</option>
                  <option value="4">Tier 4</option>
                </select>
                <small class="text-danger" style="font-size: 10px">{{
                  errors[0]
                }}</small>
              </validation-provider>
            </b-form-group>
            <!-- Cakupan -->
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Cakupan"
                rules="required"
              >
                <label for="Cakupan">
                  Cakupan <span class="text-danger">*</span>
                </label>
                <select
                  v-model="scraper_data.cakupan"
                  id="Cakupan"
                  class="form-control"
                >
                  <option value="null" disabled selected>
                    Pilih Cakupan Scraper
                  </option>
                  <option value="nasional">Nasional</option>
                  <option value="internasional">Internasional</option>
                </select>
                <small class="text-danger" style="font-size: 10px">{{
                  errors[0]
                }}</small>
              </validation-provider>
            </b-form-group>
            <!-- Domain -->
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Domain Scraper"
                rules="domain|required"
              >
                <label for="domain">
                  Domain Scraper <span class="text-danger">*</span>
                </label>
                <b-input-group class="input-group-merge">
                  <b-input-group-prepend is-text>
                    <feather-icon icon="GlobeIcon" />
                  </b-input-group-prepend>

                  <b-form-input
                    v-model="scraper_data.domain"
                    id="domain"
                    placeholder="Masukkan Domain Scraper"
                    @input="domainFormatter"
                  />
                </b-input-group>
                <small class="text-danger" style="font-size: 10px">{{
                  errors[0]
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-form>
        </validation-observer>
      </b-card-body>
    </b-card>
    <!-- custom modal footer -->
    <template #modal-footer>
      <!-- button batal -->
      <b-button
        variant="outline-danger"
        size="sm"
        class="float-right mr-05"
        @click="cancelAddScraper"
      >
        Batal
      </b-button>
      <!-- button simpan -->
      <b-button
        variant="outline-info"
        size="sm"
        class="float-right"
        @click.prevent="addNewScraper"
      >
        Simpan
      </b-button>
    </template>
  </b-modal>
</template>
<script>
import {
  BModal,
  VBModal,
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BForm,
  BFormGroup,
  BInputGroup,
  BInputGroupPrepend,
  BFormInput,
  BButton,
  BAlert,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, domain } from "@validations";
import useJwt from "@/auth/jwt/useJwt";
import floating_loader from "@/views/components/floatingLoader.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  name: "addScraper",
  components: {
    ValidationProvider,
    ValidationObserver,
    BModal,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BForm,
    BFormGroup,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    BButton,
    BAlert,
    "loader-component": floating_loader,
  },
  data() {
    return {
      is_loading: false,
      scraper_data: {
        name: null,
        file_name: null,
        status: "1",
        enable: "1",
        tier: null,
        cakupan: null,
        domain: "",
      },
      //   validation
      required,
      domain,
    };
  },
  created() {},
  methods: {
    addNewScraper() {
      this.$refs.scraperRules.validate().then((success) => {
        if (success) {
          this.is_loading = true;
          let api = process.env.VUE_APP_API_URL + "scraper/add";
          let params = {
            name: this.scraper_data.name,
            file_name: this.scraper_data.file_name,
            status: this.scraper_data.status,
            enable: this.scraper_data.enable,
            tier: parseInt(this.scraper_data.tier),
            cakupan: this.scraper_data.cakupan,
            domain: this.scraper_data.domain
              .trim()
              .toLowerCase()
              .replaceAll(/\s/g, ""),
          };
          useJwt.axiosIns
            .post(api, { data: params })
            .then(() => {
              this.scraper_data = {
                name: null,
                status: "1",
                enable: "1",
                tier: null,
                cakupan: null,
              };
              this.$bvModal.hide("modal-add-scraper");
              this.$parent.getData();
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: "Berhasil",
                  icon: "CheckCircleIcon",
                  variant: "success",
                  text: `Scraper berhasil ditambahkan`,
                },
              });
            })
            .catch((err) => {
              console.log(err);
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: "Gagal",
                  icon: "XCircleIcon",
                  variant: "danger",
                  text: `Scraper gagal ditambahkan`,
                },
              });
            })
            .finally(() => {
              this.is_loading = false;
            });
        }
      });
    },
    cancelAddScraper() {
      (this.scraper_data = {
        name: null,
        status: "1",
        enable: "1",
        tier: null,
        cakupan: null,
      }),
        this.$bvModal.hide("modal-add-scraper");
    },
    domainFormatter() {
      this.scraper_data.domain = this.scraper_data.domain
        .toLowerCase()
        .replaceAll(/\s/g, "");
    },
  },
  directives: {
    "b-modal": VBModal,
  },
};
</script>
<style>
.modal-title {
  padding-top: 1rem;
  padding-bottom: 1rem;
  font-size: 20px !important;
  font-weight: bold;
}
</style>
